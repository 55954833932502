.accordion {
    border-radius: 26px;
    overflow: hidden;
    background-color:#f2f4f6 ;
}

.accordion-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    cursor: pointer;
}



.accordion-icon {
    font-size: 24px;
    margin-left: 8px;
}

.accordion-content {
    padding: 16px;
}